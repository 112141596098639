import {
  CircularProgress, TextFieldProps,
} from '@mui/material';
import { MuiTextInput } from '@shared/components';
import { useDebounce } from '@shared/hooks';
import { useFormikContext } from 'formik';
import React from 'react';
import { formDataTypes } from '../../duck';
import { TargetTestUrl } from '@api-client';
import { globalQueries } from '@shared/duck';
import { CanceledError } from 'axios';

type BaseUrlProps = {
  cliFlow: boolean;
  readOnly?: boolean;
  setUrlCheckLoading: React.Dispatch<React.SetStateAction<boolean>>;
} & TextFieldProps

const BaseUrl: React.FC<BaseUrlProps> = ({
  cliFlow,
  readOnly = false,
  setUrlCheckLoading,
  ...props
}) => {
  const { values, setFieldValue, setFieldTouched, touched } = useFormikContext<formDataTypes.FormDataFormValues>();
  const [value, setValue] = React.useState<string>(values.baseUrl || '');
  const [enableCheck, setEnableCheck] = React.useState<boolean>(false);
  const { debouncedValue = values.baseUrl, onChange: onDebounceChange } = useDebounce<string>('', 1000);
  const [checkData, setCheckData] = React.useState<TargetTestUrl>();
  const [isTestUrlCanceled, setTestUrlCanceled] = React.useState<boolean>(false);
  const [abortController, setAbortController] = React.useState(new AbortController());

  const { checkPublicUrl, isLoading: isUrlCheckLoading, resetMutation } = globalQueries.useCheckPublicUrl(abortController.signal);

  React.useEffect(() => {
    setUrlCheckLoading(isUrlCheckLoading);
  }, [isUrlCheckLoading]);

  React.useEffect(() => {
    if (cliFlow) {
      setFieldValue('isUrlAccessible', true);
    } else {
      if (value && debouncedValue && !enableCheck) {
        setEnableCheck(true);
      }
    }
  }, [cliFlow]);

  React.useEffect(() => {
    /** Note: case when you only choose existing target */
    if (values.target && !values.newTargetName) {
      if (isUrlCheckLoading) {
        resetMutation();
        setTestUrlCanceled(true);
      }

      if (!values.isUrlAccessible) {
        setFieldValue('isUrlAccessible', true);
      }

      onDebounceChange(values.target.location || '');
      setEnableCheck(true);

      /** Note: case when you edit target before previous check is done */
    } else if (values.target && values.newTargetName && values.baseUrl) {
      setValue(values.baseUrl || '');
      onDebounceChange(values.baseUrl || '');
      setEnableCheck(true);

      /** Note: case when you remove target & create new one */
    } else if (values.newTargetName && values.baseUrl) {
      setValue(values.baseUrl || '');
      onDebounceChange(values.baseUrl || '');
      setEnableCheck(true);
    } else {
      if (isUrlCheckLoading) {
        resetMutation();
        setTestUrlCanceled(true);
      }
      setEnableCheck(false);
    }
  }, [values.target?.id, values.newTargetName]);

  React.useEffect(() => {
    values.baseUrl && !touched.baseUrl && setFieldTouched('baseUrl', true);

    if (values.baseUrl !== value) {
      setValue(values.baseUrl || '');
    }
  }, [values.baseUrl]);

  React.useEffect(() => {
    if (values.baseUrl !== value) {
      setFieldValue('baseUrl', value);
    }
  }, [value]);

  React.useEffect(() => {
    if (debouncedValue && enableCheck) {
      testUrl();
    }
  }, [debouncedValue, enableCheck, cliFlow]);

  const testUrl = async () => {
    if (!readOnly && value) {
      try {
        const { data } = await checkPublicUrl(value);
        setCheckData(data);
      }
      catch (e) {
        if (e instanceof CanceledError) {
          // canceled previous request
        }
      }
    }
  };

  React.useEffect(() => {
    if (checkData) {
      const isCorrectCheckData = checkData.requested_url.includes(value);

      if (isCorrectCheckData && !isTestUrlCanceled) {
        setValue(checkData.requested_url);
        setFieldValue('isUrlAccessible', cliFlow ? true : checkData.is_accessible);
      } else if (isTestUrlCanceled) {
        setTestUrlCanceled(false);
        setCheckData(undefined);
      }
    }

    setEnableCheck(false);
  }, [checkData]);

  const handleOnChange = (value: string) => {
    setUrlCheckLoading(true);
    setValue(value);
    onDebounceChange(value);

    if (value) {
      setEnableCheck(true);
    } else if (!values.isUrlAccessible) {
      setFieldValue('isUrlAccessible', true);
    }
  };

  return (
    <MuiTextInput
      id='base-url'
      placeholder='Enter name URL...'
      value={value}
      onBlur={props.onBlur}
      name={props.name}
      error={props.error}
      onChange={(e) => {
        abortController.abort();
        setAbortController(new AbortController());
        handleOnChange(e.target.value);
        props.onChange?.(e);
      }}
      InputProps={{
        readOnly: readOnly,
        endAdornment: (
          <>
            {isUrlCheckLoading && <CircularProgress color='inherit' size={16} />}
            {props.InputProps?.endAdornment}
          </>
        ),
      }}
    />
  );
};

export default BaseUrl;