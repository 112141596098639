import { Stack, Tooltip, Typography } from '@mui/material';
import { MinusCircle } from '@phosphor-icons/react';
import { tableTypes, UISeverityDots } from '@shared/components';
import { allRoutesEnum, globalEnums, globalModels, globalUtils } from '@shared/duck';
import { muiPalette } from '@shared/general-mui-theme';
import { DisruptedIcon } from 'views/components/v1/Icons';
import { formatTimeSpan } from 'views/utils/dates';
import useTimer from 'views/utils/useTimer';

export const scansColumns: tableTypes.ColumnProps<globalModels.ScansViewModelNew>[] = [
  {
    title: 'Target',
    columnKey: 'targetName',
    minWidth: '20rem',
    cellInfo: {
      getIcon(item) {
        return (
          <Stack position='relative'>
            {globalUtils.getTargetTypeIcon(
              item.targetType.toString()?.toUpperCase() as globalEnums.ETargetType,
              item.isInProgress,
            )}
            {item.isDisrupted && (
              <Tooltip
                arrow
                title='This scan was disrupted'
                placement='top'
              >
                <Stack
                  sx={{ position: 'absolute', top: '-5px', left: '-5px' }}
                >
                  <DisruptedIcon size='tiny' />
                </Stack>
              </Tooltip>
            )}
            {item.isAborted && (
              <Tooltip
                arrow
                title='This scan was aborted manually'
                placement='top'
              >
                <Stack
                  sx={{ position: 'absolute', top: '-5px', left: '-5px' }}
                >
                  <MinusCircle size={18} weight='fill' color={muiPalette.orange?.[400]}/>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        );
      },
      description(item) {
        const { time: currentTime } = useTimer({
          fromDate: item.startedAt,
          formatter: formatTimeSpan,
          isActive: item.isInProgress,
        });

        return (
          item.isDisrupted && item.completedIn
            ?
            'Disrupted' + ' | ' + `Completed in: ${formatTimeSpan(Math.round(item.completedIn))}`
            :
            item.completedIn && !item.isInProgress
              ? `${item.isAborted ? 'Aborted after': 'Completed in'}: ${formatTimeSpan(Math.round(item.completedIn))}`
              :
              item.isInProgress ? 'In Progress' + ' | ' + `${item.percentComplete}%` + ' | ' + `${currentTime}` : ''
        );
      },
      href(item) {
        return allRoutesEnum.ScanDetailsFindings.replace(':scanId', item.id);
      }
    },
    customCellRenderer(value) {
      return (
        <Typography variant='subtitle2' fontWeight='600'>
          {value as string}
        </Typography>
      );
    },
  },

  {
    title: 'Issues',
    columnKey: 'vulnPathsStat',
    minWidth: '15rem',
    customCellRenderer(_, item) {
      return (
        <UISeverityDots values={item.issuesStat || []} inProgress={item.isInProgress} />
      );
    },
  },
];