import { AxiosInstance, AxiosResponse } from 'axios';
import { AuthStateCookie, ReturnToCookie, ApiUrl, Configuration, SsoCliCallbackUrl } from './constants';
import { BillingHeaders, RxUsersModule } from 'models/User';
import * as api from 'api-client';
import { BaseAPI } from 'api-client/base';
import { allRoutesEnum, globalConstants } from '@shared/duck';
import { getCookie, removeCookie, setCookie } from '@shared/duck/utils';

interface Api<T extends BaseAPI> {
  new(configuration?: api.Configuration, basePath?: string, axios?: AxiosInstance): T;
}

export const configureApi: <T extends BaseAPI>(type: Api<T>, axiosClient: AxiosInstance) => T = (apiConstructor, axiosClient) => {
  return new apiConstructor(Configuration, Configuration.basePath, axiosClient);
};

export const getReadmeLoginUrl = (searchParams?: URLSearchParams) => {
  const result = new URL('/api/v1/auth/readme/login', ApiUrl);
  // pass all search params to a login endpoint
  searchParams?.forEach((value, key) => {
    result.searchParams.set(key, value);
  });
  return result;
};

export const isSocialSignOutFunc = (): boolean => {
  const params = new URLSearchParams(window.location.search);
  return params.has('socialSignOut');
};

export const makeAuthStateAndSetCookie = (length: number) => {
  let state = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    state += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  setCookie(AuthStateCookie, state, 5);
  return state;
};

export const checkAuthStateAndRemoveCookie = (state: string | null): boolean => {
  const originalState = getCookie(AuthStateCookie);
  removeCookie(AuthStateCookie);
  return state === originalState;
};

export const getBillingHeaders = (response: AxiosResponse): BillingHeaders => {
  return {
    plan: response.headers['x-billing-plan'],
    status: response.headers['x-billing-status'],
    priceId: response.headers['x-billing-price-id'],
    endingDate: response.headers['x-billing-period-end-date'],
    pastDueDate: response.headers['x-billing-past-due-limit'],
    latestInvoiceDate: response.headers['x-billing-latest-invoice-date'],
    pendingContactSalesRequests: response.headers['x-billing-pending-sales-request-count'],
    freeScansLimit: response.headers['x-billing-free-scans-limit'],
    scansPerformed: response.headers['x-billing-scans-performed-count'],
  };
};

export type AuthErrorType = 'emailNotVerified' | 'userBlocked';

export const tryContinueSocialLogin = async (authApi: api.AuthApi): Promise<{ error?: AuthErrorType, returnTo?: string }> => {
  let returnTo: string | undefined = undefined;
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const state = params.get('state');
  if (code && checkAuthStateAndRemoveCookie(state)) {
    const response = await authApi.authTokenSocialCreate(
      {
        authCodeToJWTRequest: { code: code },
        cookie: '1', // jwt in cookie
      },
      { validateStatus: null },
    );
    if (response.status === 400) {
      const errorCode = (response.data as any)?.errors?.[0]?.code;
      if (errorCode === 'email_not_verified') {
        return { error: 'emailNotVerified' };
      }
    }
    else if (response.status === 403) {
      const errorCode = (response.data as any)?.errors?.[0]?.code;
      if (errorCode === 'user_blocked') {
        return { error: 'userBlocked' };
      }
    }
    returnTo = getCookie(ReturnToCookie);
    if (!returnTo || returnTo === '/') {
      returnTo = allRoutesEnum.Home;
    }
  }
  removeCookie(ReturnToCookie);
  return { returnTo };
};

export const tryContinueSSOLogin = async (authApi: api.AuthApi): Promise<{ notInvited?: boolean; invalidCode?: boolean; userBlocked?: boolean, returnTo?: string }> => {
  let returnTo: string | undefined = undefined;
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const state = params.get('state');
  // There is no state when SSO is initialized by Identity Provider
  if (code && (!state || checkAuthStateAndRemoveCookie(state))) {
    const response = await authApi.authTokenSsoCreate(
      {
        ssoCodeToJWTRequest: { code: code },
        cookie: '1', // jwt in cookie
      },
      { validateStatus: null },
    );
    if (response.status === 403) {
      const errorCode = (response.data as any)?.errors?.[0]?.code;
      if (errorCode === 'not_invited') {
        return { notInvited: true };
      }
      else if (errorCode === 'user_blocked') {
        return { userBlocked: true };
      }
    }
    else if (response.status == 400) {
      return { invalidCode: true };
    }
    returnTo = getCookie(ReturnToCookie);
    if (!returnTo || returnTo === '/') {
      returnTo = allRoutesEnum.Home;
    }
  }
  removeCookie(ReturnToCookie);
  return { returnTo };
};

export const socialLoginRedirect = async (users: RxUsersModule, authApi: api.AuthApi, cliState?: string) => {
  const redirectUri = cliState ? SsoCliCallbackUrl : origin;
  const authState = cliState ? cliState : makeAuthStateAndSetCookie(9);
  const params = new URLSearchParams(window.location.search);
  const toSignup = params.has('signup');
  const email = params.get('email');
  setCookie(ReturnToCookie, window.location.pathname, 5);
  const sai = users.socialAuthInfo?.dto;
  if (sai) {
    document.location =
      'https://' +
      sai.domain +
      '/authorize?response_type=code&client_id=' +
      sai.client_id +
      '&redirect_uri=' +
      redirectUri +
      '&scope=openid%20email%20profile&state=' +
      authState +
      (toSignup ? '&screen_hint=signup' : '') +
      (email ? '&login_hint=' + email : '') +
      (cliState ? '&' + globalConstants.CLI_STATE_PARAM + '=' + btoa(cliState) : '');
  } else {
    // Fallback: get Social Login URL from the API
    const response = await authApi.authSocialRetrieve({ redirectUri, state: authState });
    const authorizationUrl = new URL(response.data.authorization_url);
    if (toSignup) {
      authorizationUrl.searchParams.set('screen_hint', 'signup');
    }
    if (email) {
      authorizationUrl.searchParams.set('login_hint', email);
    }
    if (cliState) {
      authorizationUrl.searchParams.set(globalConstants.CLI_STATE_PARAM, btoa(cliState));
    }
    document.location = authorizationUrl.toString();
  }
};

export const socialSignOutRedirect = async (users: RxUsersModule, authApi: api.AuthApi) => {
  const sai = users.socialAuthInfo?.dto;
  if (sai) {
    document.location = 'https://' + sai.domain + '/v2/logout?client_id=' + sai.client_id + '&returnTo=' + origin;
  } else {
    // Fallback: get Social Logout URL from the API
    const response = await authApi.authSocialLogoutRetrieve();
    const logoutUrl = new URL(response.data.logout_url);
    // Replace returnTo with current origin to preserve localhost or Vercel Preview URL:
    logoutUrl.searchParams.set('returnTo', origin);
    document.location = logoutUrl.toString();
  }
};

interface ErrorPageProps {
  code: string;
  message: string;
  details?: string;
  to_signup?: boolean;
}

export const toErrorPage = ({ code, message, details, to_signup }: ErrorPageProps) => {
  let path =
    '/error?error=' +
    encodeURIComponent(code) +
    '&error_description=' +
    encodeURIComponent(message) +
    (
      to_signup ? '&to_signup=1' : ''
    );

  if (details)
    path +=
      '&error_details=' +
      encodeURIComponent(details);

  window.location.replace(path);
};

export const toServiceUnavailablePage = () => {
  toErrorPage({
    code: 'service_unavailable',
    message: 'Service is unavailable. Please try again later.',
    details: 'It seems like you are on a private network or you have some restrictions on your network. ' +
      'Please, contact your network operator and ask him to give you the access to nightvision.net platform.'
  });
};
