import { OpenApiTarget } from '@api-client';
import React from 'react';

export interface CliCommandsContextProps {
  fileAttached?: boolean;

  currTarget?: OpenApiTarget;
  authenticationName?: string;

  apiUrl?: string;
  newFileUrl?: string;
  isApiUrlValid?: boolean;

  initTargetName?: string;
  newTargetName?: string;
  newTargetUrl?: string;
  projectId?: string;

  configuration?: {
    excluded_url_patterns: string[];
    excluded_x_paths: string[];
  };

  isTargetNameChanged?: boolean;
  isTargetLocationChanged?: boolean;
  isNewTarget?: boolean;
  isTargetUrlPatternsChanged?: boolean;
  isTargetXPathsChanged?: boolean;
  isFileUrlChanged?: boolean;
}

export const CliCommandsContext = React.createContext<CliCommandsContextProps>({});