import * as api from 'api-client';
import React from 'react';
import { RxUsersModule } from 'models/User';
import { AxiosResponse } from 'axios';
import { allRoutesEnum, globalEnums } from '@shared/duck';
import { NavigateFunction } from 'react-router-dom';
import { appConstants, appUtils } from './duck';
import { getCookie } from '@shared/duck/utils';
import { AuthErrorType, configureApi, makeAuthStateAndSetCookie } from './duck/utils';
import AllRoutesEnum from '@shared/duck/enums/all-routes-enum';

// version = '0.1.0';
export class App {
  private readonly _axiosInstance = appConstants.AxiosClient;

  readonly authApi = configureApi(api.AuthApi, this._axiosInstance);
  readonly scansApi = configureApi(api.ScansApi, this._axiosInstance);
  readonly credentialsApi = configureApi(api.CredentialsApi, this._axiosInstance);
  readonly targetsApi = configureApi(api.TargetsApi, this._axiosInstance);
  readonly issuesApi = configureApi(api.IssuesApi, this._axiosInstance);
  readonly enumsApi = configureApi(api.EnumsApi, this._axiosInstance);
  readonly projectsApi = configureApi(api.ProjectsApi, this._axiosInstance);
  readonly nucleiReposApi = configureApi(api.NucleiRepositoriesApi, this._axiosInstance);
  readonly usersApi = configureApi(api.UserApi, this._axiosInstance);
  readonly issueKindApi = configureApi(api.IssueKindsApi, this._axiosInstance);
  readonly issueWriteupsApi = configureApi(api.IssueWriteupsApi, this._axiosInstance);
  readonly statisticsApi = configureApi(api.StatisticApi, this._axiosInstance);
  readonly paymentsApi = configureApi(api.PaymentsApi, this._axiosInstance);
  readonly contactSalesApi = configureApi(api.ContactSalesApi, this._axiosInstance);

  readonly users = new RxUsersModule(this.usersApi);

  constructor() {
    this._initializeInterceptors();
  }

  private _initializeInterceptors() {
    this._axiosInstance.interceptors?.response?.use(
      response => {
        const isLogoutRequest = response.config.url?.endsWith('/logout/cookie');
        if (!isLogoutRequest) {
          this._updateBillingStatus(response);
        }
        return response;
      },
      async error => {
        error.response && this._updateBillingStatus(error.response);

        const config = error.config;
        const isRefreshTokenRequest = config.url?.includes('auth/token/refresh');
        const isCsrfError = !!error?.response?.data?.errors?.find(
          (x: any) => x.code == 'permission_denied' && x.detail?.startsWith('CSRF Failed:'),
        );
        const noFullSubscription = !this.checkHasFullSubscription();

        if (error?.response?.status === 401) {
          if (isRefreshTokenRequest) {
            await this.authenticate();
          } else if (!config.isRetryRequest) {
            config.isRetryRequest = true;
            const results = await this.authApi.authTokenRefreshCreate({
              cookie: '1',
            });

            if (results.status === 200) {
              return this._axiosInstance.request(config);
            }
          }
        } else if (error?.response?.status === 403) {
          if (isCsrfError) {
            await this.authenticate(); // updates CSRF header for subsequent requests

            // Header in current request config is not updated - have to set it manually:
            config.headers[appConstants.CsrfHeaderName] = this._axiosInstance.defaults.headers.common[appConstants.CsrfHeaderName];
            return this._axiosInstance.request(config);
          }
          else if (noFullSubscription) {
            window.location.pathname = allRoutesEnum.SelectBillingPlan;
          }
        }
        return Promise.reject(error);
      },
    );
  }

  /**
   * Check if the user has active subscription with payments.
   */
  checkHasPayingSubscription(): boolean {
    const plans: (globalEnums.SubscriptionPlan | undefined)[] = [
      globalEnums.SubscriptionPlan.API_ENVY,
      globalEnums.SubscriptionPlan.PROFESSIONAL,
      globalEnums.SubscriptionPlan.ENTERPRISE,
    ];
    return plans.includes(this.users.plan);
  }

  /**
   * Check if the user has a Professional or Enterprise subscription.
   */
  checkHasFullSubscription(): boolean {
    const plans: (globalEnums.SubscriptionPlan | undefined)[] = [
      globalEnums.SubscriptionPlan.PROFESSIONAL,
      globalEnums.SubscriptionPlan.ENTERPRISE,
      globalEnums.SubscriptionPlan.TRIAL,
    ];
    return plans.includes(this.users.plan);
  }

  checkHasEnterpriseSubscription(): boolean {
    return this.users.plan === globalEnums.SubscriptionPlan.ENTERPRISE;
  }

  checkIfScanLimitReached(): boolean {
    return this.users.plan === globalEnums.SubscriptionPlan.TRIAL &&
      Number(this.users.scansPerformed) >= Number(this.users.freeScansLimit);
  }

  async logout(): Promise<void> {
    await this.authApi.authTokenLogoutCookieCreate();
    await appUtils.socialSignOutRedirect(this.users, this.authApi);
  }

  async openStripeCheckoutSession(priceId: string) {
    const response = await this.paymentsApi.paymentsCheckoutRetrieve({ priceId });
    window.location.href = response.data.url;
  }

  async openStripeFreeTrialCheckoutSession() {
    const response = await this.paymentsApi.paymentsCheckoutTrialRetrieve();
    window.location.href = response.data.url;
  }

  async openStripeCustomerPortal() {
    const response = await this.paymentsApi.paymentsCustomerPortalRetrieve();
    window.location.href = response.data.url;
  }

  redirectToReadme(searchParams: URLSearchParams) {
    const readmeLoginUrl = appUtils.getReadmeLoginUrl(searchParams);
    window.location.replace(readmeLoginUrl);
  }

  openReadme(path?: string) {
    const searchParams = new URLSearchParams();
    if (path) {
      searchParams.append('redirect', path);
    }
    const readmeLoginUrl = appUtils.getReadmeLoginUrl(searchParams);
    window.open(readmeLoginUrl, '_blank');
  }

  private _activateFreshChat(user: { email: string; firstName: string; lastName: string | null, id: string }) {
    (window as any).fcWidget.init({
      token: 'bb4980b0-c517-4c7b-9b2c-40f426febd59',
      host: 'https://wchat.freshchat.com',
    });
    (window as any).fcWidget?.user.create({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      externalId: user.id,
    });
  }

  private _deactivateFreshChat() {
    (window as any).fcWidget.destroy();
  }


  private _updateBillingStatus(response: AxiosResponse) {
    const billingHeaders = appUtils.getBillingHeaders(response);
    this.users.setBillingStatus(billingHeaders);
  }

  activateFreshChat(enable: boolean) {
    if (!this.users.me)
      return;

    const currentUser = this.users.me.dto;
    if (enable) {
      this._activateFreshChat({
        email: currentUser.email,
        firstName: currentUser.first_name,
        lastName: currentUser.last_name,
        id: currentUser.id,
      });
    } else {
      this._deactivateFreshChat();
    }
  }

  async authenticate(navigate?: NavigateFunction, onAuthError?: (error: AuthErrorType) => void): Promise<void> {
    try {
      const { returnTo, error } = await appUtils.tryContinueSocialLogin(this.authApi);
      if (error) {
        onAuthError?.(error);
        return;
      } else {
        let csrf = await this.users.fetchMeAndGetCsrfToken(); // populates this.users.me
        if (!this.users.me) {
          const isSocialSignOut = appUtils.isSocialSignOutFunc();
          if (isSocialSignOut) {
            return appUtils.socialSignOutRedirect(this.users, this.authApi);
          }
          return appUtils.socialLoginRedirect(this.users, this.authApi);
        }
        // authenticated
        if (!csrf) {
          // backward compatibility
          csrf = getCookie('csrftoken');
        }
        if (csrf) {
          this._axiosInstance.defaults.headers.common[appConstants.CsrfHeaderName] = csrf;
        }

        // Successfully logged in
        if (returnTo && navigate) {
          navigate(returnTo, { replace: true });
        }
      }
    } catch (e) {
      appUtils.toServiceUnavailablePage();
    }
  }

  async authenticateSSO(navigate: NavigateFunction): Promise<{ userBlocked?: boolean; notInvited?: boolean; invalidCode?: boolean; }> {
    try {
      const { returnTo, notInvited, invalidCode, userBlocked } = await appUtils.tryContinueSSOLogin(this.authApi);
      if (userBlocked || notInvited || invalidCode) {
        return { userBlocked, notInvited, invalidCode };
      } else {
        let csrf = await this.users.fetchMeAndGetCsrfToken(); // populates users.me
        if (!this.users.me) {
          return {}; // stay at SSO route
        }
        // authenticated
        if (!csrf) {
          // backward compatibility
          csrf = getCookie('csrftoken');
        }
        if (csrf) {
          this._axiosInstance.defaults.headers.common[appConstants.CsrfHeaderName] = csrf;
        }

        // Successfully logged in
        if (returnTo && navigate) {
          navigate(returnTo, { replace: true });
        }
      }
    } catch (e) {
      appUtils.toServiceUnavailablePage();
    }
    return {};
  }

  async startSSO(email: string, cliState?: string): Promise<{ notFound?: boolean, notInvited?: boolean }> {
    const state = cliState ? cliState : makeAuthStateAndSetCookie(9);
    const redirectUri = cliState ? appConstants.SsoCliCallbackUrl : window.location.origin + AllRoutesEnum.SSO;
    const response = await this.authApi.authSsoRetrieve(
      {
        email,
        state,
        redirectUri,
      },
      { validateStatus: null }
    );
    if (response.status !== 200) {
      if (response.status === 403) {
        return { notInvited: true };
      }
      else {
        return { notFound: true };
      }
    }
    else {
      window.location.href = response.data.authorization_url;
      return {};
    }
  }
}

export const AppModelContext = React.createContext<App>(null as unknown as App);
