import { globalEnums } from '@shared/duck';
import React from 'react';
import { InfoCircleIcon, MoreVerticalIcon } from 'views/components/v1/Icons';
import {
  Menu, MenuItem, MenuList, MenuTrigger,
  IconButton, Switch
} from '@contentful/f36-components';
import { Tooltip, Stack } from '@mui/material';
interface ActionsInfoMenuProps {
  isRaw: boolean;
  setIsRaw: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActionsInfoMenu: React.FC<ActionsInfoMenuProps> = ({
  isRaw,
  setIsRaw,
}) => {

  return (
    <>
      <Menu closeOnSelect={false}>
        <MenuTrigger>
          <IconButton
            variant={globalEnums.EButtonVariants.SECONDARY}
            icon={<MoreVerticalIcon />} aria-label='actions' />
        </MenuTrigger>
        <MenuList>
          <MenuItem>
            <Switch name='view-har' isChecked={isRaw} onChange={() => setIsRaw(!isRaw)}>
              <Stack direction='row' gap='1rem' alignItems='center'>
                View HAR
                <Tooltip title='HTTP Archive Record (HAR)'>
                  <span>
                    <InfoCircleIcon size='small' variant={globalEnums.EIconVariants.SECONDARY} />
                  </span>
                </Tooltip>
              </Stack>
            </Switch>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};

export default ActionsInfoMenu;