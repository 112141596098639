import { UseQueryOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  OpenApiSpecUrl,
  OpenApiTarget,
  TargetsApiTargetsOpenapiCreateRequest,
  TargetsApiTargetsOpenapiGenerateUploadSpecUrlCreateRequest,
  TargetsApiTargetsOpenapiGetSpecUrlRetrieveRequest,
  TargetsApiTargetsOpenapiListRequest,
  TargetsApiTargetsOpenapiRetrieveRequest,
  TargetsApiTargetsOpenapiUpdateRequest,
} from 'api-client';
import { useApp } from '@shared/hooks';
import { TargetsQueries } from './queriesKeys';
import { getNextPageParam, useFlatResults } from './utils';
import { AxiosError, AxiosResponse } from 'axios';

const useCreateTargetsOpenApi = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsOpenapiCreateRequest) => targetsApi.targetsOpenapiCreate(request),
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [TargetsQueries.openApiTargetsList] });
      queryClient.resetQueries({ queryKey: [TargetsQueries.targetsList] });
    },
  });

  return { createOpenApiTargets: mutateAsync };
};

const useUpdateTargetsOpenApi = () => {
  const { targetsApi } = useApp();
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsOpenapiUpdateRequest) => targetsApi.targetsOpenapiUpdate(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.openApiTargetsList] });
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.targetsList] });
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.openApiTarget] });
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.targetById] });
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.targetsAuthList] });
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.targetsAppList] });
    },
  });

  return { updateOpenApiTargets: mutateAsync };
};

const useGetOpenApiTargetsList = (request: TargetsApiTargetsOpenapiListRequest) => {
  const { targetsApi } = useApp();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [TargetsQueries.openApiTargetsList, ...Object.values(request)],
    queryFn: ({ pageParam = request.page || 1 }) => targetsApi.targetsOpenapiList({ ...request, page: pageParam }),

    getNextPageParam: getNextPageParam,
  });

  const openApiTargets = useFlatResults(data);

  return {
    openApiTargets,
    isOpenApiTargetsListLoading: isLoading || isFetchingNextPage,
    openApiTargetsHasNextPage: hasNextPage,
    fetchNextOpenApiTargetsPage: fetchNextPage,
  };
};

const useGetOpenApiSpecUrl = (
  request: TargetsApiTargetsOpenapiGetSpecUrlRetrieveRequest,
  config: Partial<UseQueryOptions<AxiosResponse<OpenApiSpecUrl>, AxiosError>> = {}
) => {
  const { targetsApi } = useApp();
  const { data, isLoading, fetchStatus } = useQuery({
    queryKey: [TargetsQueries.specUrlById, request.id],
    queryFn: () => targetsApi.targetsOpenapiGetSpecUrlRetrieve(request),
    ...config,
  });

  return {
    url: data?.data,
    isUrlLoading: isLoading && fetchStatus !== 'idle',
  };
};

const useGetOpenApiSpecUrlFromTargetMutation = () => {
  const { targetsApi } = useApp();

  const { mutateAsync } = useMutation({
    mutationFn: (request: TargetsApiTargetsOpenapiGetSpecUrlRetrieveRequest) => targetsApi.targetsOpenapiGetSpecUrlRetrieve(request),
  });

  return {
    getOpenApiSpecUrl: mutateAsync,
  };
};

const useGetOpenApiTarget = (
  request: TargetsApiTargetsOpenapiRetrieveRequest,
  config: Partial<UseQueryOptions<AxiosResponse<OpenApiTarget>, AxiosError>> = {}
) => {
  const { targetsApi } = useApp();
  const { data, isLoading } = useQuery({
    queryKey: [TargetsQueries.openApiTarget, request.id],
    queryFn: () => targetsApi.targetsOpenapiRetrieve(request),
    ...config,
  });

  return {
    openApiTarget: data?.data,
    isOpenApiTargetLoading: isLoading,
  };
};

const useGetOpenApiTargetMutation = (signal: AbortSignal) => {
  const { targetsApi } = useApp();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: TargetsApiTargetsOpenapiRetrieveRequest) => targetsApi.targetsOpenapiRetrieve(request, { signal: signal }),
  });

  return {
    getTarget: mutateAsync,
    isLoading: isLoading,
  };
};

const useGetUploadSpecUrl = () => {
  const { targetsApi } = useApp();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (request: TargetsApiTargetsOpenapiGenerateUploadSpecUrlCreateRequest) => targetsApi.targetsOpenapiGenerateUploadSpecUrlCreate(request),
  });

  return {
    getUploadUrl: mutateAsync,
    isLoading: isLoading,
  };
};

export {
  useCreateTargetsOpenApi,
  useGetOpenApiTargetsList,
  useGetOpenApiTarget,
  useGetOpenApiTargetMutation,
  useGetOpenApiSpecUrl,
  useGetOpenApiSpecUrlFromTargetMutation,
  useUpdateTargetsOpenApi,
  useGetUploadSpecUrl,
};
