import { Box, Button, Stack, Typography } from '@mui/material';
import { MuiTable, tableTypes } from '@shared/components';
import React from 'react';
import { harUtils } from './duck';
import { globalModels } from '@shared/duck';
import { useMedia } from '@shared/hooks';
import { HarUploadModal } from './components';

interface HarTabProps {
  targetId: string;
}

const HarTab: React.FC<HarTabProps> = ({
  targetId,
}) => {
  const { isSmallScreen } = useMedia();
  const [currentPage, setCurrentPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openModal, setOpenModal] = React.useState(false);

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  React.useEffect(() => {
    if (currentPage > 0) {
      setCurrentPage(0);
    }
  }, [targetId]);

  const {
    harColumns,
    harFiles,
    totalItemsCount,
    isHarFilesListLoading,
  } = harUtils.useHarTableApi(
    {
      page: currentPage,
      targetId: targetId,
      pageSize: rowsPerPage,
    },
  );

  const tableConfig: tableTypes.MuiTableProps<globalModels.HarFileModel> = React.useMemo(() => {
    return {
      data: harFiles,
      columns: harColumns,
      loading: isHarFilesListLoading,
      noContentMessage: 'No HAR files found',
      pagination: {
        count: totalItemsCount,
        page: currentPage,
        rowsPerPage: rowsPerPage,
        onPageChange: (e, newPage) => handleChangePage(newPage),
        onRowsPerPageChange: (e) => handleChangeRowsPerPage(e),
      },
    };
  }, [
    harFiles,
    isHarFilesListLoading,
    totalItemsCount,
    currentPage,
    rowsPerPage,
  ]);

  const onOpenModal = () => {
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Stack
        marginTop='1.5rem'
        width='100%'
        gap={1.5}
      >
        <Stack width='100%' direction={isSmallScreen ? 'column' : 'row'} gap='1rem' alignItems={isSmallScreen ? 'unset' : 'center'} justifyContent='space-between'>
          <Typography variant='default'>
            Attach recorded HTTP Traffic of user interactions to send specific requests and check for related vulnerabilities.
          </Typography>
          <Box flexShrink='0'>
            <Button
              fullWidth
              variant='contained'
              onClick={() => onOpenModal()}
            >
              {'Upload files'}
            </Button>
          </Box>
        </Stack>

        <Box display='flex' overflow={'auto'}>
          <MuiTable
            {...tableConfig}
          />
        </Box>
      </Stack>

      {openModal && (
        <HarUploadModal
          onClose={onCloseModal}
        />
      )}
    </>
  );
};

export default HarTab;
