import { LoginStatusEnum, Scan } from '@api-client';

// TODO: Move into a shared component
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import { CredentialsLink, CredentialsBasicInfo } from '../components';
import { DataItem } from '../components/creds-basic-info/credentials-basic-info';
import { findIndex, startCase } from 'lodash';
import { CredentialsStatusBadge, CookieStatusBadge } from './components';
import { ContentGrid, StyledCodeFragment, TableStyled } from './playwright-script-credentials.styled';
import React, { CSSProperties } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'views/components/v1/Icons';
import {
  Table,
  TableCell, Button, IconButton, GridItem
} from '@contentful/f36-components';
import { AttentionGray, AuthenticationGray, CopyGray } from '@assets';
import { NoContentPlaceholder, MuiContentLoader } from '@shared/components';
import { globalEnums, globalQueries } from '@shared/duck';
import { ScanStatusEnum } from 'models/Enums';
import { Box, Stack, Typography } from '@mui/material';
import InfoPopupContent from '../info-popup-content';

const infoPopupProps = {
  title: 'What’s Login Status',
  content: <InfoPopupContent />,
};

const emptyArray = (length: number) => Array.from({ length }, () => '');
const RETRY_FETCH_DELAY_MS = 10_000;
const MIN_CODE_LINES = 21;

// interface PlaywrightScriptCredentialsProps {
//   scan?: Scan;
// }

const PlaywrightScriptCredentials = ({
  scan,
}: { scan?: Scan }): JSX.Element => {
  const galleryRef = React.useRef<any>();
  const scrollRef = React.useRef<any>();
  const imageRef = React.useRef<any>();
  const [noAuth, setNoAuth] = React.useState(false);

  const scanInProgress = scan?.status == ScanStatusEnum.IN_PROGRESS;
  const [slideIndex, setSlideIndex] = React.useState(0);
  const {
    playwrightLoginStatus: pStatus,
    isPlaywrightLoginStatusLoading,
  } = globalQueries.useGetScanPlaywrightLoginStatus({
    id: scan?.id || '',
  }, {
    retry: scanInProgress,
    retryDelay: RETRY_FETCH_DELAY_MS,
    onError: () => setNoAuth(true)
  });
  const credentials = scan?.credentials;
  const fullScriptLines = React.useMemo(() => {
    return (
      credentials?.script_content || ''
    ).split('\n');
  }, [credentials]);

  const isAuthDeleted = credentials?.is_deleted;

  const slideIndexToScriptLineIndex = React.useMemo(() => {
    const statementLines = pStatus?.page_statement_lines || [];
    const statementLineIndexes: number[] = [];

    let scriptLineIndex = -1;
    statementLines.forEach((line: string) => {
      scriptLineIndex = findIndex(fullScriptLines, (l, i) => {
        if (i <= scriptLineIndex) {
          return false;
        }

        return l.replaceAll('\\', '').trim() === line.trim();
      });
      statementLineIndexes.push(scriptLineIndex);
    });

    return [...statementLineIndexes, scriptLineIndex + 1];
  }, [fullScriptLines, pStatus]);

  const codeLineIndex = React.useMemo(() => {
    return slideIndexToScriptLineIndex[slideIndex] + 1;
  }, [slideIndexToScriptLineIndex, slideIndex]);

  const lastSlideIndex = React.useMemo(() => {
    return pStatus?.uploaded?.length ? pStatus?.uploaded?.length - 1 : 0;
  }, [pStatus]);

  const playwrightCode = React.useMemo(() => {
    if (fullScriptLines.length < MIN_CODE_LINES) {
      return [...fullScriptLines, ...emptyArray(MIN_CODE_LINES - fullScriptLines.length)].join('\n') || '';
    }

    return [...fullScriptLines, ''].join('\n');
  }, [fullScriptLines]);

  const slides = React.useMemo(() => {
    const { uploaded } = pStatus || {};
    return uploaded?.reverse()
      .map((url: string) => (
        { original: url, thumbnail: url }
      )) || [];
  }, [pStatus]);

  const items: DataItem[] = React.useMemo(() => {
    return [
      {
        title: 'Login status',
        value: <CredentialsStatusBadge status={pStatus?.login_status as LoginStatusEnum} />,
      },
      {
        title: 'Authentication type',
        value: 'Browser-based Authentication',
      },
      {
        title: 'Authentication credential',
        value: <CredentialsLink credentials={credentials} targetProjectId={scan?.target.project || ''} />,
      },
      {
        title: 'Final login state prediction',
        value: startCase(pStatus?.final_state_prediction || ''),
      },
      {
        title: 'Playwright page statements executed',
        value: `${pStatus?.page_statement_percent_complete || 0}%`,
      },
    ];
  }, [credentials, pStatus]);

  const onShowLastScreenshot = React.useCallback(() => {
    galleryRef.current?.slideToIndex(lastSlideIndex);
    setActiveIndex(lastSlideIndex);
    setScroll(lastSlideIndex);
  }, [lastSlideIndex]);

  const onSlideChange = React.useCallback((index: number) => {
    setSlideIndex(index);
    setActiveIndex(index);
    setScroll(index);
  }, []);

  const hasScreenshots = slides.length !== 0;
  const hasCookies = pStatus?.cookies?.length !== 0;
  const style: CSSProperties = {};
  if (hasCookies) {
    style.paddingBottom = '1.5rem';
  }

  const [activeIndex, setActiveIndex] = React.useState(-1);

  const setScroll = (index: number) => {
    if (scrollRef.current && imageRef.current) {
      const slideWidth = imageRef.current.clientWidth;
      const scrollTo = index * slideWidth;
      scrollRef.current.scrollLeft = scrollTo - slideWidth / 2;
    }
  };

  const onImageClick = (index: number) => {
    onSlideChange(index);
    galleryRef.current?.slideToIndex(index);
    setActiveIndex(index);
  };

  React.useEffect(() => {
    setSlideIndex(lastSlideIndex);
    setActiveIndex(lastSlideIndex);
    setScroll(lastSlideIndex);
  }, [lastSlideIndex]);


  return (
    <MuiContentLoader
      isLoading={isPlaywrightLoginStatusLoading || (scanInProgress && !pStatus)}
    >
      {noAuth ? (
        <NoContentPlaceholder
          infoPopupProps={infoPopupProps}
          height='18.75rem'
          message='No Authentication credential was added'
          iconSrc={AttentionGray}
          padded
        />
      ): (
        <>
          <CredentialsBasicInfo items={items} />
          <ContentGrid style={style}>
            <GridItem className='auth-script'>
              <Box className='header'>
                <Typography variant='subtitle1' fontWeight='600'>Authentication Script</Typography>
              </Box>
              <Box>
                {isAuthDeleted ? (
                  <Box p='0 1rem 0.5rem 1rem'>
                    <NoContentPlaceholder
                      height='28rem'
                      message='Authentication deleted'
                      iconSrc={AuthenticationGray}
                    />
                  </Box>
                ) : (
                  <StyledCodeFragment
                    code={playwrightCode}
                    language={globalEnums.SupportedLangs.python}
                    plugins={[globalEnums.SupportedPlugins.lineNumbers, globalEnums.SupportedPlugins.lineHighlight]}
                    highlightLines={`${codeLineIndex}`}
                    variant={pStatus?.login_status === 'success' ? 'positive' : 'negative'}
                  />
                )}
              </Box>
            </GridItem>
            {isAuthDeleted ? (
              <Stack className='gallery' gap='0'>
                <Stack direction='row' alignItems='center' gap='1rem' className='header'>
                  <Typography variant='subtitle1' fontWeight='600'>Screenshots</Typography>
                  <Button className='show-last-button' isDisabled={true} onClick={onShowLastScreenshot}>
                Show last screenshot
                  </Button>
                </Stack>
                <Box>
                  <Stack p='0 1rem 0.5rem 1rem'>
                    <NoContentPlaceholder
                      height='28rem'
                      message='Screenshots deleted'
                      iconSrc={CopyGray}
                    />
                  </Stack>
                </Box>
              </Stack>
            ) : hasScreenshots && (
              <GridItem className='gallery'>
                <Box className='header'>
                  <Typography variant='subtitle1' fontWeight='600'>Line {codeLineIndex} render</Typography>
                  <Button className='show-last-button' onClick={onShowLastScreenshot}>
                Show last screenshot
                  </Button>
                </Box>
                <Box>
                  <ImageGallery
                    items={slides}
                    autoPlay={false}
                    showPlayButton={false}
                    showIndex={true}
                    showBullets={false}
                    lazyLoad={true}
                    infinite={false}
                    ref={galleryRef}
                    showThumbnails={false}
                    renderLeftNav={(onClick, disabled) => (
                      <IconButton
                        onClick={onClick}
                        isDisabled={disabled}
                        icon={<ChevronLeftIcon variant={globalEnums.EIconVariants.PRIMARY} />}
                        size='large'
                        aria-label='left'
                        className='left-arrow'
                      />
                    )}
                    renderRightNav={(onClick, disabled) => (
                      <IconButton
                        onClick={onClick}
                        isDisabled={disabled}
                        icon={<ChevronRightIcon variant={globalEnums.EIconVariants.PRIMARY} />}
                        size='large'
                        aria-label='left'
                        className='right-arrow'
                      />
                    )}
                    startIndex={lastSlideIndex}
                    onBeforeSlide={onSlideChange}
                    isRTL={false}
                  />
                  <Box sx={{ p: '1rem 1rem 0 1rem' }}>
                    <Typography variant='default' className='subheading-thumb'>All screenshots</Typography>
                  </Box>
                  <Stack
                    direction='row' ref={scrollRef}
                    sx={{ overflow: 'auto', scrollBehavior: 'smooth', m: '1rem' }}>
                    {slides.map((key, index) => {
                      return (
                        <Box ref={imageRef} key={index} className='thumbnails-galery'>
                          <Box className={activeIndex === index ? 'thumbnails active' : 'thumbnails'}>
                            <img alt={'thumbnail'} onClick={() => onImageClick(index)} src={key.thumbnail} height='108'></img>
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                </Box>
              </GridItem>
            )}
          </ContentGrid>

          {
            hasCookies && (
              <ContentGrid style={{ gridTemplateColumns: '1fr !important' }} className='cookies-content'>
                <GridItem>
                  <Box className='header' sx={{ borderRight: '0 !important' }}>
                    <Typography variant='subtitle1' fontWeight='600'>Cookies</Typography>
                  </Box>
                  <TableStyled>

                    <Table.Head>
                      <Table.Row>
                        {Object.keys(pStatus?.cookies?.[0] || {}).map((value) => (
                          <TableCell key={value}>{value.charAt(0).toUpperCase() + value.slice(1)}</TableCell>
                        ))}
                      </Table.Row>
                    </Table.Head>
                    <Table.Body>
                      {pStatus?.cookies && pStatus?.cookies?.map((item, number) => (
                        <Table.Row key={'item' + number.toString()}>
                          {Object.keys(item || {}).map((value, number2) => (
                            typeof item[value] === 'boolean' ?
                              <TableCell key={'value' + number.toString() + number2.toString()}>
                                {<CookieStatusBadge status={item[value]} />}
                              </TableCell> :
                              <TableCell
                                className={value === 'value' ? 'value-col' : ''}
                                key={'value' + number.toString() + number2.toString()}
                              >{item[value]}
                              </TableCell>
                          ))}
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </TableStyled>

                </GridItem>
              </ContentGrid>
            )
          }
        </>
      )}
    </MuiContentLoader>
  );
};

export default PlaywrightScriptCredentials;
