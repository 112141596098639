import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { GenerateSpec, UploadSpec, AddUrl } from './components';
import { useFormikContext } from 'formik';
import { formDataTypes } from '../../../components';
import { globalConstants } from '@shared/duck';
import { CliCommandsContext, CliCommandsContextProps } from './duck';
import _ from 'lodash';
import { OpenApiTarget } from '@api-client';

enum CLI_COMMANDS {
  GENERATE = 'generate',
  UPLOAD = 'upload',
  URL = 'url',
}

export interface CliCommandsProps {
  fileAttached: boolean;
}

const CliCommands: React.FC<CliCommandsProps> = ({
  fileAttached,
}) => {
  const [alignment, setAlignment] = React.useState(CLI_COMMANDS.GENERATE);
  const handleChange = (_: any, newAlignment: CLI_COMMANDS) => {
    newAlignment && setAlignment(newAlignment);
  };

  const { values, isValid } = useFormikContext<formDataTypes.FormDataFormValues>();
  const target = values.target as OpenApiTarget;
  const apiUrl = values.specUrlFromCommandsPage;
  const newFileUrl = values.fileUrl;
  const newUrlPatterns = values.configuration.excluded_url_patterns?.trim().split('\n').filter(Boolean) || [];
  const newXPaths = values.configuration.excluded_x_paths?.trim().split('\n').filter(Boolean) || [];
  const initTargetName = target?.name;
  const initTargetUrl = target?.location;
  const newTargetName = values.newTargetName;
  const newTargetUrl = values.baseUrl;
  const initUrlPatterns = target?.configuration?.excluded_url_patterns || [];
  const initXPaths = target?.configuration?.excluded_x_paths || [];

  const contextValue: CliCommandsContextProps = {
    fileAttached: fileAttached,
    currTarget: target,
    authenticationName: values.authentication?.name,
    apiUrl: apiUrl,
    newFileUrl: newFileUrl,
    isApiUrlValid: !!apiUrl && isValid && values.isSpecUrlFromCommandsPageValid && values.isSpecUrlFromCommandsPageValid2,

    initTargetName: initTargetName,
    newTargetName: newTargetName,
    newTargetUrl: newTargetUrl,
    projectId: values.project?.id,

    configuration: {
      excluded_url_patterns: newUrlPatterns.length
        ? newUrlPatterns.map(el => `${globalConstants.EXCLUDE_URL_FLAG} "${el}"`)
        : [`${globalConstants.EXCLUDE_URL_FLAG} ""`],
      excluded_x_paths: newXPaths.length
        ? newXPaths.map(el => `${globalConstants.EXCLUDE_XPATH_FLAG} "${el}"`)
        : [`${globalConstants.EXCLUDE_XPATH_FLAG} ""`],
    },

    isTargetNameChanged: !!newTargetName && (initTargetName !== newTargetName),
    isTargetLocationChanged: initTargetUrl !== newTargetUrl,
    isNewTarget: values.createNewTargetCheckbox,
    isTargetUrlPatternsChanged: !_.isEqual(initUrlPatterns, newUrlPatterns),
    isTargetXPathsChanged: !_.isEqual(initXPaths, newXPaths),
    isFileUrlChanged: !!target?.swaggerfile_url && target?.swaggerfile_url !== newFileUrl && !values.isFileUrlRemoved,
  };

  return (
    <CliCommandsContext.Provider value={contextValue}>
      {!fileAttached && (
        <ToggleButtonGroup
          color='primary'
          value={alignment}
          exclusive
          onChange={handleChange}
          aria-label='swagger-file'
          sx={{ marginBottom: '0.5rem' }}
        >
          <ToggleButton value={CLI_COMMANDS.GENERATE}>Generate Swagger file</ToggleButton>
          <ToggleButton value={CLI_COMMANDS.UPLOAD}>Upload Swagger file</ToggleButton>
          <ToggleButton value={CLI_COMMANDS.URL}>OpenAPI URL</ToggleButton>
        </ToggleButtonGroup>
      )}
      {(
        () => {
          switch (alignment) {
            case CLI_COMMANDS.GENERATE:
              return <GenerateSpec />;
            case CLI_COMMANDS.UPLOAD:
              return <UploadSpec />;
            case CLI_COMMANDS.URL:
              return <AddUrl />;
          }
        }
      )()}
    </CliCommandsContext.Provider>
  );
};

export default CliCommands;