import { User, UserMeUpdateUpdateErrorResponse400 } from '@api-client';
import { Box, Button, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { ProfileForm, UIUserAvatar } from '@shared/components';
import { muiPalette } from '@shared/general-mui-theme';
import { useApp, useMedia } from '@shared/hooks';
import { Formik, FormikHelpers } from 'formik';
// import { ProfilePassword } from './components';
import React from 'react';
import { profileUtils } from './duck';
import { globalTypes, globalUtils } from '@shared/duck';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { observer } from 'mobx-react';

const ProfileSettings: React.FC = () => {
  const { isLargeScreen, isSmallScreen } = useMedia();
  const app = useApp();
  const currentUser: User | undefined = app.users.me?.dto;

  const initialValues = React.useMemo(() => {
    return profileUtils.getInitialValues(currentUser);
  }, [currentUser]);

  const onSubmit = async (values: globalTypes.ProfileFormValues, formikHelpers: FormikHelpers<globalTypes.ProfileFormValues>) => {
    const userUpdateRequest = globalUtils.getUserUpdateRequest(values);
    try {
      await app.users.updateMe(userUpdateRequest);
      enqueueSnackbar('Personal info updated', { variant: 'success' });
    }
    catch (err) {
      const error = err as AxiosError<UserMeUpdateUpdateErrorResponse400, any>;
      const errors = error?.response?.data?.errors || [];
      for (const e of errors) {
        if (e.attr === 'username' && e.code === 'unique') {
          formikHelpers.setFieldError('username', e.detail);
        }
        if (e.attr === 'phone_number') {
          formikHelpers.setFieldError('phoneNumber', e.detail);
        }
      }
      enqueueSnackbar('Could not update user info. Please try again.', { variant: 'error' });
    }
  };

  return (
    <Box padding={isSmallScreen ? 1 : 2}>
      <Formik<globalTypes.ProfileFormValues>
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validateOnBlur
        validationSchema={profileUtils.profileValidationSchema}
      >
        {({ handleSubmit, isSubmitting, resetForm, isValid, dirty, values, initialValues, setFieldValue }) => {
          React.useEffect(() => {
            if (initialValues.username === values.username) {
              setFieldValue('usernameExists', false);
            }
          }, [values.username]);

          const isUsernameValid = values.usernameExists !== undefined && !values.usernameExists;
          return (
            <>
              <Grid
                height='fit-content'
                container
                gap={isLargeScreen ? 0 : 2}
              >
                <Grid item lg={4} xs={12}>
                  <Typography
                    fontSize='1.75rem'
                    fontWeight={600}
                    lineHeight='2.25rem'
                    letterSpacing='0.02275rem'
                    color={muiPalette.grey?.[900]}
                    paddingBottom='1.5rem'
                  >
                    {'Profile Settings'}
                  </Typography>

                  <Typography
                    fontSize='0.875rem'
                    fontWeight={500}
                    lineHeight='1.25rem'
                    letterSpacing='-0.00963rem'
                    color={muiPalette.grey?.[800]}
                  >
                    {'Personal Information'}
                  </Typography>
                  <Typography
                    variant='body2'
                    color={muiPalette.grey?.[600]}
                  >
                    {'This will be displayed on your profile.'}
                  </Typography>
                </Grid>


                <Grid
                  item
                  lg={4}
                  xs={12}
                  paddingTop={isLargeScreen ? '4rem' : 'initial'}
                >
                  <Stack flexDirection='row' alignItems='center' pb='1.5rem' gap='1rem'>
                    <UIUserAvatar
                      src={values?.avatarUrl}
                      avatarSize={3}
                    />
                    <Typography
                      fontSize='0.875rem'
                      fontWeight={400}
                      lineHeight='1.25rem'
                      letterSpacing='-0.00963rem'
                      color={muiPalette.grey?.[500]}
                      style={{ wordBreak: 'break-word', textAlign: 'right' }}
                    >
                      {values.email}
                    </Typography>
                  </Stack>

                  <ProfileForm />
                </Grid>

                {/* {!isLargeScreen && (
                  <ProfilePassword />
                )} */}

                <Grid
                  item
                  lg={4}
                  xs={12}
                  height='fit-content'
                  display='flex'
                  justifyContent={isLargeScreen ? 'flex-end' : 'space-between'}
                >
                  <Button
                    sx={{
                      width: 'fit-content',
                      marginRight: '1rem',
                    }}
                    disabled={isSubmitting || !dirty}
                    onClick={() => resetForm({ values: initialValues })}
                  >
                    {'Discard changes'}
                  </Button>
                  <Button
                    variant='contained'
                    sx={{ width: 'fit-content' }}
                    disabled={isSubmitting || !isValid || !dirty || !isUsernameValid}
                    onClick={() => handleSubmit()}
                    endIcon={isSubmitting && (
                      <CircularProgress color='inherit' size={20} />
                    )}
                  >
                    {'Update Profile'}
                  </Button>
                </Grid>

                {/* {isLargeScreen && (
                  <ProfilePassword />
                )} */}
              </Grid>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};


// https://stackoverflow.com/questions/78119678/mobx-with-useeffect-not-rendered-data-in-component/78120314#78120314
export default observer(ProfileSettings);