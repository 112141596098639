import React from 'react';
import { Description, Title } from '../../cli-commands.styled';
import { FormikControlMui, UICodeFragment } from 'shared/components';
import { SupportedLangs } from 'shared/duck/enums/global-enums';
import { SwaggerFileUrlInput } from './components';
import { globalEnums } from '@shared/duck';
import { CliCommandsContext } from '../../duck';

const AddUrl: React.FC = () => {
  const {
    fileAttached,
    authenticationName,
    apiUrl,
    projectId,
    initTargetName,
    newTargetName, newTargetUrl,
    configuration,
    isTargetNameChanged, isTargetLocationChanged, isTargetUrlPatternsChanged, isTargetXPathsChanged, isApiUrlValid,
    isNewTarget,
  } = React.useContext(CliCommandsContext);
  let step = 1;

  const createCommand = '# Create target\nnightvision target create ' +
    `${newTargetName} ${newTargetUrl}` +
    (isApiUrlValid ? ` -s ${apiUrl}` : ' -s $OPEN_API_URL') +
    (isTargetUrlPatternsChanged ? ` ${configuration?.excluded_url_patterns.join(' ')}` : '') +
    (isTargetXPathsChanged ? ` ${configuration?.excluded_x_paths.join(' ')}` : '') +
    ` -P ${projectId}` +
    ` --type ${globalEnums.ECLITargetType.API}`;

  const updateCommand = '# Update target\nnightvision target update ' +
    `${initTargetName}${isTargetNameChanged ? ` -n ${newTargetName}` : ''}` +
    `${isTargetLocationChanged ? ` -u ${newTargetUrl}` : ''}` +
    (isApiUrlValid ? ` -s ${apiUrl}` : ' -s $OPEN_API_URL') +
    (isTargetUrlPatternsChanged ? ` ${configuration?.excluded_url_patterns.join(' ')}` : '') +
    (isTargetXPathsChanged ? ` ${configuration?.excluded_x_paths.join(' ')}` : '') +
    ` -P ${projectId}`;

  const addUrlCommand = '# Update target with API URL\nnightvision target update ' +
    `${isNewTarget ? newTargetName : initTargetName} ` +
    (isApiUrlValid ? `-s ${apiUrl} ` : '-s $OPEN_API_URL ') +
    `-P ${projectId}`;

  const runCommand = '# Run scan\nnightvision scan ' +
    `${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} ` +
    `${authenticationName ? `--auth ${authenticationName} ` : ''}` +
    `-P ${projectId}`;

  return (
    <Description variant='caption'>
      <Title>{`Step ${step++}`}</Title>
      {'Enter your OpenAPI URL first.'}
      <FormikControlMui name='specUrlFromCommandsPage' style={{ marginTop: '0.5rem' }}>
        <SwaggerFileUrlInput
          placeholder='Enter OpenAPI URL here...'
        />
      </FormikControlMui>

      {isNewTarget ? (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'Let’s create a new target.'}
          {!isApiUrlValid && (<span>&nbsp;Replace <i>$OPEN_API_URL</i> with your url.</span>)}
          <UICodeFragment code={createCommand} language={SupportedLangs.bash} />
        </>
      ) :
        !isNewTarget && (isTargetNameChanged || isTargetLocationChanged || isTargetUrlPatternsChanged || isTargetXPathsChanged) && !fileAttached ? (
          <>
            <Title>{`Step ${step++}`}</Title>
            {'We have noticed that you made changes to the target; let\'s apply those'}
            {!isApiUrlValid ? (<span>.&nbsp;Replace <i>$OPEN_API_URL</i> with your url.</span>) : ':'}
            <UICodeFragment code={updateCommand} language={SupportedLangs.bash} />
          </>
        ) : !fileAttached && (
          <>
            <Title> {`Step ${step++}`}</Title>
            {'Let’s update the target'}
            {!isApiUrlValid ? (<span>.&nbsp;Replace <i>$OPEN_API_URL</i> with your url.</span>) : ':'}
            <UICodeFragment code={addUrlCommand} language={SupportedLangs.bash} />
          </>
        )
      }

      <Title>{`Step ${step++}`}</Title>
      {'Run scan:'}
      <UICodeFragment code={runCommand} language={SupportedLangs.bash} />
    </Description >
  );
};

export default AddUrl;