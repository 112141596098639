import { AppWindowContainer } from '@app/app-provider.styled';
import { AppContext, Workspace, GlobalErrorHandler, Authenticator } from '@app/components';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from '@shared/duck/contexts';
import { generalMuiTheme } from '@shared/general-mui-theme';
import dayjs from 'dayjs';
import React from 'react';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { BrowserRouter } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);

const AppProvider: React.FC = () => {
  return (
    <React.StrictMode>
      <ThemeProvider theme={generalMuiTheme}>
        <BrowserRouter>
          <AppContext>
            <GlobalErrorHandler>
              <Authenticator>
                <AppWindowContainer>
                  <SnackbarProvider>
                    <Workspace />
                  </SnackbarProvider>
                </AppWindowContainer>
              </Authenticator>
            </GlobalErrorHandler>
          </AppContext>
        </BrowserRouter>
      </ThemeProvider>
    </React.StrictMode>
  );
};

export { AppProvider };
