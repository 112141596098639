export enum HAR_UPLOAD_STATUS {
  UPLOADED,
  UPLOAD_ERROR,
  DUPLICATE_NAME,
  ERROR,
  SUBMITTING,
}

export interface HarObject {
  id?: number;
  file: File;
  fileName: string;
  uploadUrl?: string;
  status?: HAR_UPLOAD_STATUS;
  progress?: number;
}

export interface HarUploadModalValues {
  values: HarObject[]
}