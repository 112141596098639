import React from 'react';
import { globalConstants, globalQueries } from '@shared/duck';
import { ApiTargetsContentValues, importTargetsValidationSchema, useGetApiUtils } from './duck';
import { MuiContentLoader } from '@shared/components';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { ErrorContext } from '@shared/duck/contexts';
import { Button, CircularProgress, DialogActions, DialogContent, } from '@mui/material';
import { CommonContentHeader } from '../common-content-header';
import ApiTargetsContent from './api-targets-content';
import { Form, Formik } from 'formik';
import { TargetsContentValuesProps } from '../../duck';

export const ApiTargetsContentWrapper: React.FC<TargetsContentValuesProps<ApiTargetsContentValues>> = ({
  closeModal,
  onAfterCreate,
  onAfterUpdate,
  project,
  onProjectChange,
  enableProjectSelect,
  isDefaultProjectLoading,
  target,
  currentTab,
  onTabChange,
}: TargetsContentValuesProps<ApiTargetsContentValues>) => {

  const enableOpenApiRequests = !!target;
  const { setError } = React.useContext(ErrorContext);

  const { openApiTarget, isOpenApiTargetLoading } = globalQueries.useGetOpenApiTarget({
    id: target?.id || ''
  }, {
    enabled: enableOpenApiRequests,
    onError: err => errorResponseHandler(err, 'target', setError),
  });

  const [selectedFile, setSelectedFile] = React.useState<File | undefined>();
  const isSpecUploaded = !!openApiTarget?.swaggerfile_url || !!openApiTarget?.swaggerfile_name;
  const [isFileEdited, setFileEdited] = React.useState(false);

  React.useEffect(() => {
    if (isSpecUploaded) {
      const swaggerFileUrlName = openApiTarget?.swaggerfile_url?.substring(openApiTarget?.swaggerfile_url.lastIndexOf('/') + 1);
      const swaggerFileName = openApiTarget?.swaggerfile_name || swaggerFileUrlName || 'openapi-swagger.yaml';
      setSelectedFile(new File([''], swaggerFileName, { type: 'application/yaml' }));
    }
    else {
      setSelectedFile(undefined);
    }
  }, [openApiTarget]);

  const {
    onCreateApiTarget,
    onUpdateApiTarget,
    getInitialValues,
  } = useGetApiUtils({
    openApiTarget: openApiTarget,
    project: project,
    onAfterUpdate: onAfterUpdate,
    onAfterCreate: onAfterCreate,
    isFileEdited: isFileEdited,
    selectedFile: selectedFile,
  });

  return (
    <Formik<ApiTargetsContentValues>
      initialValues={getInitialValues()}
      onSubmit={openApiTarget ? onUpdateApiTarget : onCreateApiTarget}
      validateOnChange
      validateOnBlur
      isInitialValid={!!openApiTarget}
      validateOnMount={!!openApiTarget}
      validationSchema={importTargetsValidationSchema}
      enableReinitialize
    >
      {({ values, isValid, isSubmitting, initialValues, handleSubmit }) => {
        const initTarget = initialValues.newTarget;
        const currTarget = values.newTarget;
        const isExclusionsChanged = JSON.stringify(initialValues.configuration) !== JSON.stringify(values.configuration);

        const isInitialEqualsToCurrent = currTarget?.name === initTarget?.name
          && currTarget?.location === initTarget?.location
          && values.isFileUrl === initialValues.isFileUrl
          && ((initialValues.isFileUrl && currTarget.fileLocation === initTarget.fileLocation)
            || (!initialValues.isFileUrl && currTarget.file === initTarget.file))
          && !isExclusionsChanged;

        const isFileUrlChanged = currTarget.fileLocation !== initTarget?.fileLocation;

        const disabled = !isValid || !project || isSubmitting || isInitialEqualsToCurrent
          || !currTarget.isAccessTested || !currTarget.isNameTested
          || (values.isFileUrl && (!currTarget.isFileUrlValid || (openApiTarget?.spec_status && globalConstants.ERROR_SPEC_STATUSES.includes(openApiTarget?.spec_status) && !isFileUrlChanged)))
          || (!values.isFileUrl && (openApiTarget?.spec_status && globalConstants.ERROR_SPEC_STATUSES.includes(openApiTarget?.spec_status) && !isFileEdited));

        return (
          <>
            <Form style={{ overflow: 'auto', display: 'flex', flexDirection: 'column' }}>
              <DialogContent dividers>
                <MuiContentLoader
                  isLoading={isDefaultProjectLoading
                    || (enableOpenApiRequests && isOpenApiTargetLoading)
                    || (isSpecUploaded && !selectedFile)
                  }
                >
                  <CommonContentHeader
                    project={values.newTarget.project}
                    enableProjectSelect={enableProjectSelect}
                    onProjectChange={onProjectChange}
                    showToggleButtons={!target}
                    currentTab={currentTab}
                    onTabChange={onTabChange}
                  />

                  <ApiTargetsContent
                    openApiTarget={openApiTarget}
                    project={values.newTarget.project}
                    setFileEdited={state => setFileEdited(state)}
                  />
                </MuiContentLoader>
              </DialogContent>
            </Form>

            <DialogActions sx={{ gap: '0.75rem' }}>
              <Button
                size='small'
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                size='small'
                onClick={() => handleSubmit()}
                variant='contained'
                endIcon={isSubmitting && (
                  <CircularProgress color='inherit' size={18} />
                )}
                disabled={disabled}
              >
                {openApiTarget ? 'Update' : 'Create Target'}
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik >
  );
};

export default ApiTargetsContentWrapper;
