import { Issue } from '@api-client';
import { Dialog, DialogContent } from '@mui/material';
import { UIDialogTitle, UITabs } from '@shared/components';
import { CurlTab, NucleiTab } from './components';
import React from 'react';
import { globalEnums, globalTypes } from '@shared/duck';
import { issueValidationUtils } from './duck';

interface ModalPopup {
  openModal: boolean;
  closeModal: () => void;
  issue?: Issue,
}

interface RequestItem {
  name: string;
  value: string;
}

const TABID_NUCLEI = 0;
const TABID_CURL = 1;

const IssueValidationModal: React.FC<ModalPopup> = ({
  openModal,
  closeModal,
  issue,
}) => {
  const response = React.useMemo(() => issue?.extra_info?.http_requests?.[0] || {}, [issue?.extra_info?.http_requests]);

  // need to skip header with Accept-Encoding, because in some cases it says to provide content in compression format, like
  // 'Accept-Encoding: gzip', which requires additional options
  const headers = React.useMemo(
    () => response?.headers && issueValidationUtils.getHeaders(response?.headers),
    [response?.headers],
  );
  const nucleiTemplateId = issue?.extra_info?.['template-id'];
  const requestData = response?.postData?.text ?? '';
  const dataInputs = response?.postData?.params?.map((item: RequestItem) => `-d "${item.name}=${item.value}"`)
    .join(' \\\n');
  const nucleiType = issue?.extra_info?.info?.type || '';
  const nucleiSubType = issue?.extra_info?.info?.sub_type || '';

  const [tabsValue, setTabsValue] = React.useState<string | number>('');

  React.useEffect(() => {
    setTabsValue(nucleiType && nucleiSubType ? TABID_NUCLEI : TABID_CURL);
  }, [nucleiSubType, nucleiSubType]);

  const link = (
    issue?.extra_info?.info?.link || ''
  ) + '?raw=true';

  const queryParam = React.useMemo(() => response.url ? new URL(response.url).search : '', [response.url]);

  const location = React.useMemo(
    () => issue && issueValidationUtils.getLocation(issue, queryParam),
    [issue?.scan.location, issue?.url_path, queryParam],
  );

  const validateWithNuclei = (
    nucleiType === globalEnums.ENucleiType.BUILT_IN &&
    nucleiSubType === globalEnums.ENucleiSubType.PROJECT_DISCOVERY
  ) ? (
      `nuclei -u ${location} \\\n` +
    `-id ${nucleiTemplateId} \\\n` +
    '-silent ' +
    (
      headers && `\\\n${headers}`
    )
    ) : (
      `curl -L "${link}" \\\n` +
    `> ${nucleiTemplateId}.yaml \n\n` +
    `nuclei -u ${location} -t ${nucleiTemplateId}.yaml \\\n` +
    '-silent ' +
    (
      headers && `\\\n${headers}`
    )
    );

  const { validateWithCurl, validateWithCurlWindows } = React.useMemo(() => {
    return issue ? issueValidationUtils.validateWithCurl(
      issue,
      headers,
      requestData,
      dataInputs,
      location,
    ) : { validateWithCurl: '', validateWithCurlWindows: '' };
  }, [issue?.http_method, headers, requestData, dataInputs, location]);

  const handleTabsChange = (value: string | number) => {
    setTabsValue(value);
  };

  const tabsConfig: globalTypes.UITabsProps = React.useMemo(() => {
    return {
      tabsProps: {
        value: tabsValue,
        onChange: (e, value) => handleTabsChange(value),
        'aria-label': 'validate_tabs',
      },
      tabs: [
        {
          label: 'Validate with Nuclei',
          value: TABID_NUCLEI,
          isExist: nucleiType && nucleiSubType,
        },
        {
          label: 'Validate with curl',
          value: TABID_CURL,
        },
      ],
      tabsPanels: [
        {
          tabPanelId: TABID_NUCLEI,
          openTabId: tabsValue,
          children: NucleiTab({ validateWithNuclei }),
        },
        {
          tabPanelId: TABID_CURL,
          openTabId: tabsValue,
          children: CurlTab({ validateWithCurl, validateWithCurlWindows }),
        },
      ],
    };
  }, [tabsValue, validateWithCurl, validateWithNuclei, nucleiType, nucleiSubType]);

  return (
    <Dialog
      fullWidth
      open={openModal}
      onClose={closeModal}
    >
      <UIDialogTitle
        title={`Validate issue ID: ${issue?.id}`}
        onCloseBtnAction={closeModal}
      />

      <DialogContent
        sx={{
          borderBottom: 'none',
          padding: '0 1.5rem 1.5rem',
        }}
      >
        <UITabs {...tabsConfig} />
      </DialogContent>
    </Dialog>
  );
};

export default IssueValidationModal;