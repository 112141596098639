import { OpenApiTarget, GitHubTargetConfiguration, OpenApiTargetRequest, SpecStatusEnum } from '@api-client';
import { ScanTargetOpenApi } from '@shared/components/create-targets/components/target-modal-content/components/api-content/duck';
import { globalConstants, globalQueries, globalUtils } from '..';
import { NewOpenApiTarget } from '../types';
import { CanceledError } from 'axios';
import { appConstants } from '@app/components/context/provider/duck';
import { useQueryClient } from '@tanstack/react-query';
import { TargetsQueries } from '../queries/queriesKeys';

export const onCreateNewTarget = () => {
  const { getUploadUrl } = globalQueries.useGetUploadSpecUrl();
  const { createOpenApiTargets } = globalQueries.useCreateTargetsOpenApi();

  const onCreate = async (targetToCreate: NewOpenApiTarget, isFileUrl: boolean, configuration: GitHubTargetConfiguration) => {
    const target: ScanTargetOpenApi = {
      ...targetToCreate,
      credentials: new Map(),
      isAccessTested: false,
    };

    const request: OpenApiTargetRequest = {
      name: target.name || '',
      credentials_id: null,
      project: target.project,
      location: target.location,
      swaggerfile_url: isFileUrl ? target.fileLocation : undefined,
      internet_accessible: target.isAccessible,
      configuration: configuration,
    };

    const {
      data: [newTarget],
    } = await createOpenApiTargets({
      openApiTargetRequest: [request],
    });

    if (!isFileUrl && target.file) {
      const { data } = await getUploadUrl({ id: newTarget.id || '', openApiGetUploadUrlRequest: { filename: target.file.name } });

      if (data.url) {
        const file = target.file;
        await appConstants.AxiosClient.put(data.url, file, {
          headers: {
            // axios for some reason adding a default Content-Type: application/json header.
            // thats the reason why the signature is failing to match.
            // solution: include empty header
            'Content-Type': ''
          }
        });
        newTarget.swaggerfile_name = target.file.name;
      }
    }

    return newTarget;
  };

  return { onCreate };
};

export const onUpdateNewTarget = () => {
  const { getUploadUrl } = globalQueries.useGetUploadSpecUrl();
  const { updateOpenApiTargets } = globalQueries.useUpdateTargetsOpenApi();
  const queryClient = useQueryClient();

  const onUpdate = async (
    targetToUpdate: NewOpenApiTarget, id: string, configuration: GitHubTargetConfiguration,
    isFileUrl: boolean, isFileEdited?: boolean,
    isSwaggerFileChanged?: boolean, isConfigurationChanged?: boolean) => {
    const target: ScanTargetOpenApi = {
      ...targetToUpdate,
      credentials: new Map(),
      isAccessTested: false,
    };

    const request: OpenApiTargetRequest = {
      name: target.name || '',
      project: target.project,
      location: target.location,
      swaggerfile_url: isFileUrl ?
        isSwaggerFileChanged ? target.fileLocation : undefined
        : undefined,
      configuration: isConfigurationChanged ? configuration : undefined,
    };

    const {
      data: newTarget,
    } = await updateOpenApiTargets({
      id: id,
      openApiTargetUpdateRequest: request,
    });

    if (!isFileUrl && targetToUpdate.file && isFileEdited) {
      const { data } = await getUploadUrl({ id: newTarget.id || '', openApiGetUploadUrlRequest: { filename: targetToUpdate.file.name } });

      if (data.url) {
        const content = await targetToUpdate.file.text();
        await appConstants.AxiosClient.put(data.url, content, {
          headers: {
            // axios for some reason adding a default Content-Type: application/json header.
            // thats the reason why the signature is failing to match.
            // solution: include empty header
            'Content-Type': ''
          }
        });
        newTarget.swaggerfile_name = targetToUpdate.file.name;
      }
    }
    if (isSwaggerFileChanged || isFileEdited) {
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.openApiTarget, id] });
      queryClient.invalidateQueries({ queryKey: [TargetsQueries.specUrlById, id] });
    }

    return newTarget;
  };

  return { onUpdate };
};


const mapSpecStatusToTextMessage = new Map<SpecStatusEnum, string>([
  [SpecStatusEnum.NoSpec, 'OpenAPI spec is not specified'],
  [SpecStatusEnum.Downloading, 'Downloading OpenAPI spec'],
  [SpecStatusEnum.DownloadError, 'OpenAPI spec download failed'],
  [SpecStatusEnum.Validating, 'Validating OpenAPI spec'],
  [SpecStatusEnum.Invalid, globalConstants.INVALID_YAML_DEFINITION],
  [SpecStatusEnum.Valid, 'OpenAPI spec is valid'],
  [SpecStatusEnum.WaitingForUpload, 'Waiting for OpenAPI spec upload to complete'],
]);

export const specStatusToTextMessage = (status?: SpecStatusEnum): string => {
  return status ? mapSpecStatusToTextMessage.get(status) || '' : 'Checking OpenAPI spec';
};

interface WaitTargetForReadyToScanResponse {
  error?: string;
  aborted?: boolean;
}

export const waitTargetForReadyToScanHandler = () => {
  const controller = new AbortController();
  globalUtils.useComponentWillUnmount(() => controller.abort());
  const { getTarget } = globalQueries.useGetOpenApiTargetMutation(controller.signal);

  const waitTargetForReadyToScan = async (targetId: string,
    updateSpecStatus?: (newStatus: SpecStatusEnum) => void,
    updateTargetIsReadyToScan?: (newStatus: boolean) => void
  ): Promise<WaitTargetForReadyToScanResponse> => {
    let count = 0;
    let lastTargetRes: OpenApiTarget | undefined;
    const maxAttempCount = 10;
    const errorSpecStatuses: SpecStatusEnum[] = [SpecStatusEnum.DownloadError, SpecStatusEnum.Invalid];

    while (
      !lastTargetRes?.is_ready_to_scan
      && (!lastTargetRes?.spec_status || !errorSpecStatuses.includes(lastTargetRes.spec_status))
      && !controller.signal.aborted
    ) {
      if (count > maxAttempCount) {
        return { error: 'Spec validation is still in progress. Please try again later.' };
      }
      count++ > 0 && await new Promise((r) => setTimeout(r, 3000));
      if (targetId) {
        try {
          const { data } = await getTarget({
            id: targetId,
          });
          lastTargetRes = data;
          updateTargetIsReadyToScan?.(data.is_ready_to_scan);
          updateSpecStatus?.(data.spec_status);
        }
        catch (e) {
          if (e instanceof CanceledError) {
            // canceled prevoius request
          }
        }
      }
    }
    if (!lastTargetRes?.is_ready_to_scan && !controller.signal.aborted) {
      if (!!lastTargetRes?.spec_status && errorSpecStatuses.includes(lastTargetRes.spec_status)) {
        return ({ error: specStatusToTextMessage(lastTargetRes?.spec_status) });
      }
      else return ({ error: 'Spec validation is still in progress. Please try again later.' });
    }
    else if (controller.signal.aborted) {
      return ({ aborted: true });
    }
    return {};
  };

  return {
    waitTargetForReadyToScan: waitTargetForReadyToScan,
  };
};