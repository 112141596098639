/* ---- DOCUMENTATION URL ---- */

import { SpecStatusEnum } from '@api-client';

export const MAIN_DOCS_URL = 'https://docs.nightvision.net';
export const DOCS_PATHS = {
  ROOT: '/docs',
  SCANNING_REST_API: '/docs/scanning-public-rest-apis',
  SCANNING_REST_API_W_AUTH: '/docs/headers-and-cookies',
  SCANNING_WEB_APPS: 'docs/scanning-public-web-applications',
  RECORD_AUTH: '/docs/interactive-logins',
  INSTALLING_CLI: '/docs/installing-the-cli',
  GITHUB_ACTIONS: '/docs/github-actions',
  CLI_CHEAT_SHEET_SCANNING: '/docs/installing-the-cli#cli-usage',
  API_EXTRACTION_OVERVIEW: '/docs/api-discovery',
  FRAMEWORK_SUPPORT: '/docs/frameworks',
  CODE_TRACEBACK: '/docs/code-traceback',
};
/* ---- END ---- */

export const PAGES = {
  home: 'Home',
  scans: 'Scans',
  issues: 'Issues',
  targets: 'Targets',
  projects: 'Projects',
  authentications: 'Authentications',
  customizeNuclei: 'Customize with Nuclei',
};

export const INPUT_BORDER = {
  ERROR: '#BD002A',
  FOCUSED: '#036FE3',
  BASE: '#CFD9E0', // grey300
};

export const COMPLETED_SPEC_STATUSES: SpecStatusEnum[] = [SpecStatusEnum.Valid];
export const ERROR_SPEC_STATUSES: SpecStatusEnum[] = [SpecStatusEnum.DownloadError, SpecStatusEnum.Invalid, SpecStatusEnum.NoSpec];
export const NO_SPEC_STATUSES: SpecStatusEnum[] = [SpecStatusEnum.NoSpec, SpecStatusEnum.WaitingForUpload];

export const VULNERABLE_RESPONSE_KEY = 'vulnerable-response-body';

export const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50];

/* ---- VIDEOS START ---- */

export const LOOM_HIDE_INFO = '?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true';

export const VIDEOS_URL = {
  LOGIN_STATUS: 'https://www.loom.com/embed/b1cc86269a464af18e898ec346fbbe96?sid=c95b5312-9c14-4135-b7b9-d165f120aef7'
    + LOOM_HIDE_INFO,
  WHAT_IS_PLAYWRIGHT: 'https://www.loom.com/embed/953c683f0ab144ea88a9504a5de19873?sid=d3f7fffb-93c5-4b98-9643-9750956a386e'
    + LOOM_HIDE_INFO,
};

/* ---- VIDEOS END ---- */

/* ---- CONTACT INFO ---- */

// export const CONTACT_TEL = '+1 941-941-1929';
export const CONTACT_EMAIL = 'support@nightvision.net';
export const SALES_EMAIL = 'sales@nightvision.net';

/* ---- CONTACT INFO END ---- */

export const ALL_PROJECTS = '';

export const PLEASE_UPGRADE_TO_CREATE_PROJECT = 'Please upgrade your account to create new Projects';
export const FULL_MONTH_DATE_TIME = 'MMMM DD, YYYY @ hh:mm A';
export const FULL_MONTH_DATE = 'MMMM DD, YYYY';
export const FULL_WEEK_MONTH_DATE = 'ddd, MMMM DD YYYY';

export const RESCAN_IS_NOT_AVAILABLE_PROJECT_DELETED = 'Rescan unavailable: Project has been deleted';
export const RESCAN_IS_NOT_AVAILABLE_TARGET_DELETED = 'Rescan unavailable: Target has been deleted';
export const RESCAN_IS_NOT_AVAILABLE_AUTH = 'Rescan unavailable: Authentication used against this scan has been deleted';
export const INVALID_NAME_FORMAT = 'Invalid format: no space or special characters are allowed except -_';
export const FAILED_TO_READ_FILE = 'Failed to read file';

export const AUTHENTICATION_TOOLTIP = 'Authentication allows NightVision to scan Apps & APIs beyond login pages';
export const SCAN_LOGS_EXPIRATION_TEXT = 'Scan Logs are only available for 14 days. These Scan Logs will expire on';
export const SCREENSHOTS_EXPIRATION_TEXT = 'Screenshots are only available for 14 days after the scan. These screenshots will expire on';
export const IS_DEVELOPMENT = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const SHARE_PROJECT_NOT_ALLOWED = 'Only owner can share a project';
export const REMOVE_USER_FROM_PROJECT_NOT_ALLOWED = 'Only owner can remove user from a project';
export const REMOVE_OWNER_FROM_PROJECT_NOT_ALLOWED = 'You can\'t remove yourself from your project';
export const SHARE_FREE_PROJECT_NOT_ALLOWED = 'Sharing a free project is prohibited';

export const CANT_RENAME_FREE_PROJECT = 'Renaming a free project is prohibited';
export const CANT_DELETE_FREE_PROJECT = 'Deleting a free project is prohibited';
export const CANT_DELETE_DEFAULT_PROJECT = 'You cannot delete your default project';

export const RENAME_PROJECT_NOT_ALLOWED = 'Only owner can rename a project';
export const DELETE_PROJECT_NOT_ALLOWED = 'Only owner can delete a project';

export const INVALID_YAML_DEFINITION = 'Invalid OpenAPI definition';
export const INACCESSIBLE_URL = 'Provided url doesn\'t exist';

// SSO Authentication
export const CLI_STATE_PARAM = 'ext-cli-state';

// CLI commands
export const EXCLUDE_URL_FLAG = '--exclude-url';
export const EXCLUDE_XPATH_FLAG = '--exclude-xpath';
