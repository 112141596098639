import { Issue } from '@api-client';

interface RequestItem {
  name: string;
  value: string;
}

export const getHeaders = (headers: any) => {
  return headers.filter((item: RequestItem) => item.name !== 'Accept-Encoding' && item.name !== 'content-length')
    .map((item: RequestItem) => `-H '${item.name}: ${item.value}'`)?.join(' \\\n');
};

export const getLocation = (issue: Issue, queryParam: string) => {
  const targetUrl = issue?.scan.location || '';
  const firstSlashPos = targetUrl.indexOf('/', targetUrl.indexOf('//') + 2);
  const baseUrl = targetUrl.substring(0, firstSlashPos > -1 ? firstSlashPos : targetUrl.length);

  const filteredLoc = baseUrl.endsWith('/') ?
    baseUrl.slice(0, -1) :
    baseUrl;

  const location = filteredLoc + (
    issue?.url_path || ''
  );

  return queryParam ? `${location}${queryParam}` : location;
};

export const validateWithCurl = (
  issue: Issue,
  headers: any,
  requestData: any,
  dataInputs: any,
  location?: string,
): { validateWithCurl: string; validateWithCurlWindows: string; } => {
  const curlCommand = 'curl';
  const curlCommandWindows = 'curl.exe';

  const method = issue.http_method === 'None' ? `${curlCommand} ` : `${curlCommand} -X '${issue?.http_method}' \\\n`;
  const methodWindows = issue.http_method === 'None' ? `${curlCommandWindows} ` : `${curlCommandWindows} -X '${issue?.http_method}' \\\n`;

  const headersOrg = headers && `\\\n${headers} `;
  const flags = '\\\n--include ';

  const data = requestData ? `\\\n-d '${requestData}'` : (
    dataInputs ? `\\\n${dataInputs}` : '\\\n-d \'\''
  );
  const dataWindows = requestData ? `\\\n-d '${requestData}'` : (
    dataInputs && `\\\n${dataInputs}`
  );

  return {
    validateWithCurl: `${method}"${location}" ${flags}${headersOrg}${data}`,
    validateWithCurlWindows: `${methodWindows}"${location}" ${flags}${headersOrg}${dataWindows}`
  };
};