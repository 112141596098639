import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography, Stack, Alert } from '@mui/material';
import { FormikControlMui } from '@shared/components/formik';
import { muiPalette } from '@shared/general-mui-theme';
import { MuiTextInput } from '../text-input';

export interface TargetExclusionsProps {
  targetsCount?: number;
}

export const TargetExclusions: React.FC<TargetExclusionsProps> = ({
  targetsCount = 1,
}) => {

  return (
    <Stack>
      <Accordion>
        <AccordionSummary
          sx={{
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
            },
          }}
        >
          <Typography fontWeight='500'>Exclusions</Typography>
          <Typography
            variant='subtitle2'
            color={muiPalette.grey?.[500]}
            sx={{ opacity: '0.5' }}
          >
            &nbsp;(optional)
          </Typography>
        </AccordionSummary>
        {targetsCount === 1 ? (
          <AccordionDetails>
            <Stack gap='1.5rem' flexDirection='row'>
              <FormikControlMui name={'configuration.excluded_url_patterns'} label='Exclude URL patterns' fullWidth>
                <MuiTextInput
                  multiline
                  placeholder='Enter URLs or regex, each separated by a line break'
                  minRows='3'
                  maxRows='3'
                />
              </FormikControlMui>

              <FormikControlMui name={'configuration.excluded_x_paths'} label='Exclude clicks based on XPath' fullWidth>
                <MuiTextInput
                  multiline
                  placeholder='Enter XPath expressions, each separated by a line break'
                  minRows='3'
                  maxRows='3'
                />
              </FormikControlMui>
            </Stack>
          </AccordionDetails>
        ) : (
          <AccordionDetails>
            <Alert severity='info'>
              {'You have multiple targets. Configure each one after creation.'}
            </Alert>
          </AccordionDetails>
        )}
      </Accordion>
    </Stack>
  );
};