import {
  UIUserAvatar,
  FormikControlMui,
  MuiContentLoader,
} from '@shared/components';
import { useApp, useMedia } from '@shared/hooks';
import { errorResponseHandler } from 'views/utils/errorHandlers';
import { Project } from '@api-client';
import React from 'react';
import { BlueFolder } from '@assets';
import { allRoutesEnum, globalQueries, globalUtils, globalConstants } from '@shared/duck';
import { useNavigate } from 'react-router-dom';
import { ErrorContext, MODAL_TYPE, useGlobalModalContext } from '@shared/duck/contexts';
import { PencilSimpleLine, ShareNetwork, SignOut, Trash } from '@phosphor-icons/react';
import { Avatar, IconButton as MuiIconButton, Tooltip, Typography, Stack, Button } from '@mui/material';
import { EditProjectName } from './components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { NameWrapper } from './project-details-info.styled';
import { enqueueSnackbar } from 'notistack';
import { muiPalette } from '@shared/general-mui-theme';

export const RenameProjectValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Project name can not be empty').max(100, 'Name must be at most 100 characters')
    .when('exists', {
      is: false,
      then:
        schema => schema
          .test('isValid', globalConstants.INVALID_NAME_FORMAT, (value?: string) => {
            return globalUtils.validateName(value || '');
          }),
    }),
  exists: Yup.boolean(),
  isNameTested: Yup.boolean(),
});

export interface RenameProjectFormValues {
  name: string;
  exists: boolean;
  isNameTested: boolean;
}

interface ProjectDetailsInfoProps {
  projectId: string;
}

const ProjectDetailsInfo: React.FC<ProjectDetailsInfoProps> = ({ projectId }) => {
  const navigate = useNavigate();
  const app = useApp();
  const { setError } = React.useContext(ErrorContext);
  const fetchedProject = React.useRef<Project | undefined>();
  const { isDown } = useMedia();
  const isSmallScreen = isDown('1100');

  const [editMode, setEditMode] = React.useState(false);
  const [isMyProject, setIsMyProject] = React.useState(false);
  const [projectName, setProjectName] = React.useState('');

  const { deleteProjectHandler } = globalUtils.useDeleteProjectHandler();
  const { updateProject } = globalQueries.useUpdateProject();
  const { onLeaveProjectHandler } = globalUtils.useLeaveProjectHandler();

  const { project, isProjectLoading } = globalQueries.useGetProject(
    { id: projectId },
    {
      onSettled: data => {
        fetchedProject.current = data?.data;
        setProjectName(data?.data.name || '');
      },
      onError: (er) => errorResponseHandler(er, 'project', setError),
    },
  );

  React.useEffect(() => {
    setIsMyProject(project?.own_user?.id === app.users.me?.dto.id);
  }, [project?.own_user, app.users.me]);

  const canRename = isMyProject && !project?.is_free;

  const fullName = project?.own_user ? `${project?.own_user?.first_name} ${project?.own_user?.last_name}` : 'N/A';
  const Owner = (
    <Stack direction='row' gap='1rem' alignItems='center'>
      <UIUserAvatar
        withFullNameText={false}
        src={project?.own_user?.avatar_url || ''}
      />
      <Stack gap='0'>
        <Typography variant='default'>
          Owner
        </Typography>
        <Typography variant='default' color={muiPalette.grey?.[500]}>
          {fullName}
        </Typography>
      </Stack>
    </Stack>
  );

  const onDeleteProject = () =>
    deleteProjectHandler({
      id: project?.id || '',
      name: project?.name || '',
      onProjectDeleted: () => navigate(allRoutesEnum.Projects),
    });

  const enableRenaming = () => setEditMode(true);
  const disableRenaming = () => setEditMode(false);

  const updateProjectSubmit = async (values: RenameProjectFormValues) => {
    try {
      if (values.name !== project?.name) {
        await updateProject({ id: projectId, projectRequest: { name: values.name } });
      }
      setProjectName(values.name);
      disableRenaming();
    } catch (error) {
      enqueueSnackbar('Failed to update Project', { variant: 'error' });
    }
  };

  const { showModal } = useGlobalModalContext();
  const openShareProjectModal = () => project && showModal({
    modalType: MODAL_TYPE.ShareProjectModal,
    modalProps: {
      collaborators: project.shared_with_users_preview,
      projectId: project.id,
    },
  });

  return (
    <MuiContentLoader
      isLoading={isProjectLoading}
    >
      <Formik<RenameProjectFormValues>
        initialValues={{ name: project?.name || '', exists: false, isNameTested: true }}
        onSubmit={updateProjectSubmit}
        validateOnChange
        validateOnBlur
        validateOnMount
        validationSchema={RenameProjectValidationSchema}
      >
        {({ values, isValid }) => {
          const isFormInvalid = values.exists || !isValid;
          return (
            <Stack
              direction='row' gap='1rem' alignItems='center'
              mb={isFormInvalid && !isSmallScreen ? '2.5rem' : '1.5rem'}
            >
              <Avatar src={BlueFolder} />
              <NameWrapper direction='row' spacing={0.5} editMode={editMode}>
                {!editMode ? (
                  <>
                    <Tooltip title={projectName}>
                      <Typography
                        fontSize='1.75rem'
                        fontWeight='600'
                        lineHeight='2.25rem'
                        noWrap
                        letterSpacing='0.02275rem'
                        sx={{ p: '0.3rem 0' }}
                        onDoubleClick={enableRenaming}
                      >
                        {projectName}
                      </Typography>
                    </Tooltip>
                    {canRename && (
                      <MuiIconButton
                        size='small'
                        onClick={enableRenaming}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent !important',
                          },
                        }}
                      >
                        <PencilSimpleLine size={20} />
                      </MuiIconButton>
                    )}
                  </>
                ) : (
                  <FormikControlMui
                    name='name'
                    fullWidth
                    showError
                    absoluteError
                    tooltipError={isSmallScreen}
                    mb='0'
                  >
                    <EditProjectName />
                  </FormikControlMui>
                )}
              </NameWrapper>
              <Stack direction='row' gap='1rem' alignItems='center'>
                {Owner}

                {isMyProject ? (
                  <>
                    <Tooltip
                      title={globalUtils.getShareProjectTooltipText(isMyProject, project?.is_free)}
                      key='share-project-tooltip'
                    >
                      <span>
                        <Button
                          size='small'
                          aria-label='share_project'
                          onClick={openShareProjectModal}
                          endIcon={<ShareNetwork size={20} />}
                        >
                          Share
                        </Button>
                      </span>
                    </Tooltip>

                    <Tooltip
                      title={globalUtils.getDeleteProjectTooltipText(isMyProject, !!project?.is_free, !!project?.is_default)}
                      key='delete-project-tooltip'
                    >
                      <span>
                        <MuiIconButton
                          size='small'
                          aria-label='delete_project'
                          color='outlined'
                          onClick={onDeleteProject}
                          disabled={project?.is_free || project?.is_default}
                        >
                          <Trash size={20} />
                        </MuiIconButton>
                      </span>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip
                    title='Leave Project'
                    key='leave-project-tooltip'
                  >
                    <span>
                      <MuiIconButton
                        size='small'
                        aria-label='leave_project'
                        color='outlined'
                        onClick={() => onLeaveProjectHandler({
                          projectName: project?.name || '',
                          projectId: projectId,
                          userId: app.users.me?.dto.id || ''
                        })}
                      >
                        <SignOut size={20} />
                      </MuiIconButton>
                    </span>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          );
        }}
      </Formik>
    </MuiContentLoader>
  );
};

export default ProjectDetailsInfo;
