import React from 'react';
import { MuiTextInput } from 'shared/components';
import { CircularProgress, InputAdornment, InputBaseComponentProps } from '@mui/material';
import { globalConstants, globalUtils } from '@shared/duck';
import { useDebounce, useLaunchDarklyFlags } from '@shared/hooks';
import { Check } from '@phosphor-icons/react';
import { OpenApiTarget } from '@api-client';

export interface SpecUrlInputInputProps extends Omit<InputBaseComponentProps, 'value' | 'onChange'> {
  onAfterChange: (e: any) => void;
  currentValue?: string;
  setFileUrlValid: (res?: boolean) => void;
  setFileUrlValid2: (res?: boolean) => void;
  initialValue?: OpenApiTarget;
}

const SpecUrlInput: React.FC<SpecUrlInputInputProps> = ({
  onAfterChange,
  currentValue = '',
  setFileUrlValid,
  setFileUrlValid2,
  initialValue,
  ...innerTextInputProps
}: SpecUrlInputInputProps) => {
  const [changes] = React.useState({ count: 0 });
  const [loading, setLoading] = React.useState(false);
  const [abortController, setAbortController] = React.useState(new AbortController());
  const { debouncedValue, onChange: onDebounceChange } = useDebounce<string>(currentValue);
  const [urlFileInitialError, setUrlFileInitialError] = React.useState(innerTextInputProps.error);
  const [isInternalValid, setInternalValid] = React.useState<boolean | undefined>(initialValue ? !innerTextInputProps.error : false);
  const [isInternalValid2, setInternalValid2] = React.useState<boolean | undefined>(initialValue ? currentValue === initialValue.swaggerfile_url : undefined);

  React.useEffect(() => {
    if (initialValue) {
      const specHasError = initialValue?.spec_status && globalConstants.ERROR_SPEC_STATUSES.includes(initialValue?.spec_status);
      setUrlFileInitialError(specHasError);
      setInternalValid(!specHasError);
    }
  }, [initialValue]);

  React.useEffect(() => {
    setFileUrlValid(isInternalValid);
  }, [isInternalValid]);

  React.useEffect(() => {
    setFileUrlValid2(isInternalValid2);
  }, [isInternalValid2]);

  const { strictSwaggerSpecValidation } = useLaunchDarklyFlags();

  const fileUrlValidation = async (abortController: AbortController) => {
    if (currentValue) {
      if (currentValue === initialValue?.swaggerfile_url) {
        setInternalValid(!urlFileInitialError);
        setInternalValid2(true);
        setLoading(false);
        return;
      }
      setLoading(true);
      setInternalValid(undefined);
      const currCounter = changes.count;
      const res = await globalUtils.validateOpenApiSpec({ api: currentValue, isFile: false, abortController, strictCheck: strictSwaggerSpecValidation });
      if (res !== undefined) {
        // not aborted
        setLoading(false);
        if (changes.count === currCounter) {
          if (res.errorCode === 'access_error') {
            setInternalValid2(false);
          }
          else {
            setInternalValid(res.isValid);
            setInternalValid2(true);
          }
        }
      }
    }
  };

  React.useEffect(() => {
    fileUrlValidation(abortController);
  }, [debouncedValue]);

  return (
    <MuiTextInput
      {...innerTextInputProps}
      onBlur={(e) => {
        innerTextInputProps.onBlur?.(e);
        onAfterChange(e);
      }}
      placeholder='Enter OpenAPI URL here...'
      value={currentValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            {loading
              ? <CircularProgress size={20} color='inherit' />
              : currentValue && isInternalValid
                ? <Check size={20} color="green" />
                : <></>}
          </InputAdornment>
        )
      }}
      onChange={e => {
        onDebounceChange(e.target.value);
        abortController.abort();
        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        setLoading(false);
        changes.count++;
        setFileUrlValid(undefined);
        onAfterChange(e);
      }} />
  );
};

export default SpecUrlInput;