import React from 'react';
import { Description, Title } from '../../cli-commands.styled';
import { UICodeFragment, DocsLink } from '@shared/components';
import { SupportedLangs } from '@shared/duck/enums/global-enums';
import { globalConstants, globalEnums } from '@shared/duck';
import { CliCommandsContext } from '../../duck';

const GenerateSpec: React.FC = () => {
  const {
    fileAttached,
    currTarget,
    authenticationName,
    projectId,
    initTargetName,
    newFileUrl, newTargetName, newTargetUrl,
    configuration,
    isTargetNameChanged, isTargetLocationChanged, isTargetUrlPatternsChanged, isTargetXPathsChanged, isFileUrlChanged,
    isNewTarget,
  } = React.useContext(CliCommandsContext);

  let step = 1;

  return (
    <Description variant='caption'>
      <br />
      {'Please ensure that your API is running.'}

      {isNewTarget && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'Let’s create a new target first.'}
          <UICodeFragment
            code={
              `# Create target\nnightvision target create ${newTargetName} ${newTargetUrl}` +
              (isTargetUrlPatternsChanged ? ` ${configuration?.excluded_url_patterns.join(' ')}` : '') +
              (isTargetXPathsChanged ? ` ${configuration?.excluded_x_paths.join(' ')}` : '') +
              ` -P ${projectId} --type ${globalEnums.ECLITargetType.API}`
            }
            language={SupportedLangs.bash} />
        </>
      )}

      {!isNewTarget && (isTargetNameChanged || isTargetLocationChanged || isFileUrlChanged || isTargetUrlPatternsChanged || isTargetXPathsChanged) && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {'We have noticed that you made changes to the target; let\'s apply those first:'}
          <UICodeFragment
            code={'# Update target\nnightvision target update ' +
              `${initTargetName}${isTargetNameChanged ? ` -n ${newTargetName}` : ''}` +
              `${isTargetLocationChanged ? ` -u ${newTargetUrl}` : ''}` +
              `${isFileUrlChanged ? (currTarget?.swaggerfile_url ? ' -s ' : ' -f ') + `${newFileUrl}` : ''}` +
              (isTargetUrlPatternsChanged ? ` ${configuration?.excluded_url_patterns.join(' ')}` : '') +
              (isTargetXPathsChanged ? ` ${configuration?.excluded_x_paths.join(' ')}` : '') +
              ` -P ${projectId}`}
            language={SupportedLangs.bash} />
        </>
      )}
      {!fileAttached && (
        <>
          <Title>{`Step ${step++}`}</Title>
          {step === 2 ? 'First, move ' : 'Move '}
          {'to the repository containing the code for your API to generate the Swagger spec file. We will extract all endpoints for you.'}

          <Title>{`Step ${step++}`}</Title>
          Use the following command to generate a Swagger file. Replace <i>$LANGUAGE</i> with the language of your code. You can find the supported languages <DocsLink to={globalConstants.DOCS_PATHS.FRAMEWORK_SUPPORT}>here</DocsLink>.
          <UICodeFragment code={`# Swagger Extract\nnightvision swagger extract ./ -t ${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} --lang $LANGUAGE -P ${projectId}`} language={SupportedLangs.bash} />
        </>
      )}

      <Title>{step === 1 ? '' : `Step ${step++}`}</Title>
      {'Run scan:'}
      <UICodeFragment code={`# Run scan\nnightvision scan ${isTargetNameChanged || isNewTarget ? newTargetName : initTargetName} -P ${projectId} ${authenticationName ? `--auth ${authenticationName}` : ''}`} language={SupportedLangs.bash} />

    </Description>
  );
};

export default GenerateSpec;